type ActionMap<M extends { [index: string]: any }> = {
  [Key in keyof M]: M[Key] extends undefined ? { type: Key } : { type: Key; payload: M[Key] }
}

export type SessionType = {
  counter: number
  user?: {}
}

export type SessionActionPayload = {
  ADD_COUNTER: { count: number }
}

export type SessionActionType = ActionMap<SessionActionPayload>[keyof ActionMap<SessionActionPayload>]
export const sessionReducer = (state: SessionType, action: SessionActionType) => {
  switch (action.type) {
    case 'ADD_COUNTER':
      state.counter += action.payload.count
      return state
    default:
      return state
  }
}
