import React from 'react'
// @ts-ignore
import { Eclipse } from 'react-loading-io'

const LoadingScreen: React.FC = () => (
  <div style={{ position: 'relative', width: '100%', height: '100vh' }}>
    <div style={{ position: 'absolute', top: '0', left: '0', right: '0', bottom: '0', height: '100px', width: '100px', margin: 'auto' }}>
      <Eclipse size={160} />
    </div>
  </div>
)

export default LoadingScreen
