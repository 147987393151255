import axios from 'axios'

const Api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  timeout: 60000
})

export default Api

export interface PaymentResponse {
  id: undefined | string
  status: string
  // statusCode: PaymentStatusCode
  statusCode: string
  statusData: any
}

export interface CheckEnrollmentResponse {
  windowSize: WindowSize
  caInformation: CAInformation
}

export interface CAInformation {
  accessToken: string
  stepUpUrl: string
}

interface WindowSize {
  width: number
  height: number
}

export interface PaymentRequest {
  inquiryId: string
  amount: number
  currency: string
  paymentSource: string
  paymentSourceData: any
  installmentId?: number
  saveToken?: boolean
  useToken?: boolean
  discountAmount?: number
}

export interface PaymentCancel {
  transactionId: string
  inquiryId?: string
  transactionDate: Date
  errorMessage: string
  cancelToken: string
  errorCode: string
}

export interface PromoRequest extends PaymentRequest {}

export interface AfterDiscountRequest extends PaymentRequest {}

export interface InstallmentRequest extends PaymentRequest {}
