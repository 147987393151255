import React, { createContext, useReducer, Dispatch } from 'react'
import { SessionType, sessionReducer, SessionActionType } from 'reducers/SessionReducer'
import { inquiryReducer, InquiryActionType, InquiryType } from 'reducers/InquiryReducer'
import Api, { PaymentRequest, PaymentResponse, PromoRequest, AfterDiscountRequest, InstallmentRequest, PaymentCancel, CheckEnrollmentResponse } from 'utils/Api'
import Inquiry from 'models/Inquiry'
import { Promo } from 'models/Promo'
import { Installment } from 'models/Installment'
import { CheckEnrollmentRequest } from 'models/CheckEnrollment'

type InitialStateType = {
  session: SessionType
  inquiry: InquiryType
}

const initialState = {
  session: { counter: 0 },
  inquiry: { inquiry: null, promo: null, afterDiscount: true, installments: null }
}

const AppContext = createContext<{
  state: InitialStateType
  dispatch: Dispatch<SessionActionType | InquiryActionType>
  fetchInquiry: (id: string) => Promise<Inquiry | undefined>
  submitPayment: (req: PaymentRequest) => Promise<PaymentResponse | undefined>
  cancelTransaction: (req: PaymentCancel) => Promise<PaymentResponse | undefined>
  submitEnrollment: (req: CheckEnrollmentRequest) => Promise<CheckEnrollmentResponse | undefined>
  submitPaymentAuthenticate: (id: string, req: { authenticationCode: any }) => Promise<PaymentResponse | undefined>
  fetchPromo: (req: PromoRequest) => Promise<Promo | null | undefined>
  fetchAfterDiscount: (req: AfterDiscountRequest) => Promise<boolean | undefined>
  fetchInstallments: (req: InstallmentRequest) => Promise<Installment[] | null | undefined>
}>({
  state: initialState,
  dispatch: () => null,
  fetchInquiry: () => Promise.resolve(undefined),
  submitPayment: () => Promise.resolve(undefined),
  cancelTransaction: () => Promise.resolve(undefined),
  submitPaymentAuthenticate: () => Promise.resolve(undefined),
  fetchPromo: () => Promise.resolve(undefined),
  fetchAfterDiscount: () => Promise.resolve(undefined),
  fetchInstallments: () => Promise.resolve(undefined),
  submitEnrollment: () => Promise.resolve(undefined)
})

const mainReducer = ({ session, inquiry }: InitialStateType, action: SessionActionType | InquiryActionType) => ({
  session: sessionReducer(session, action as SessionActionType),
  inquiry: inquiryReducer(inquiry, action as InquiryActionType)
})

const AppProvider: React.FC = ({ children }) => {
  const [state, dispatch] = useReducer(mainReducer, initialState)

  const fetchInquiry: (id: string) => Promise<Inquiry> = (id: string) => {
    return Api.request<Inquiry>({
      method: 'GET',
      url: `/inquiries/${id}`
    }).then(({ status, data }) => {
      dispatch({ type: 'SET_INQUIRY', payload: data })
      return data
    })
  }

  const submitPayment: (req: PaymentRequest) => Promise<PaymentResponse> = (req) => {
    return Api.request<PaymentResponse>({
      method: 'POST',
      url: `/payments`,
      data: req
    }).then(({ status, data }) => {
      return data
    })
  }

  const submitEnrollment: (req: CheckEnrollmentRequest) => Promise<CheckEnrollmentResponse> = (req) => {
    return Api.request({
      method: 'POST',
      url: '/authenticate/check-enrollment',
      headers: {
        'Content-Type': 'application/json'
      },
      data: req
    }).then(({ status, data }) => {
      return data
    })
  }

  const cancelTransaction: (req: PaymentCancel) => Promise<any> = (req) => {
    return Api.request<PaymentResponse>({
      method: 'POST',
      url: `/cancel`,

      data: req
    }).then(({ status, data }) => {
      return data
    })
  }

  const submitPaymentAuthenticate: (id: string, req: { authenticationCode: any }) => Promise<PaymentResponse> = (id, req) => {
    return Api.request<PaymentResponse>({
      method: 'POST',
      url: `/payments/${id}/authenticate`,
      data: req
    }).then(({ status, data }) => {
      return data
    })
  }

  const fetchPromo: (req: PromoRequest) => Promise<Promo | null> = (req) => {
    return Api.request<Promo>({
      method: 'POST',
      url: `/promos/lookup`,
      data: req
    }).then(({ status, data }) => {
      dispatch({ type: 'SET_PROMO', payload: data })
      return data
    })
  }

  const fetchAfterDiscount: (req: AfterDiscountRequest) => Promise<boolean> = (req) => {
    return Api.request<boolean>({
      method: 'POST',
      url: `/afterdiscount/lookup`,
      data: req
    }).then(({ status, data }) => {
      dispatch({ type: 'SET_AFTER_DISCOUNT', payload: data })
      return data
    })
  }

  const fetchInstallments: (req: InstallmentRequest) => Promise<Installment[] | null> = (req) => {
    return Api.request<Installment[]>({
      method: 'POST',
      url: `/installments/lookup`,
      data: req
    }).then(({ status, data }) => {
      if (data !== null) {
        data.sort(function (a, b) {
          return a.planPeriod - b.planPeriod
        })
      }

      dispatch({ type: 'SET_INSTALLMENT', payload: data })
      return data
    })
  }

  return (
    <AppContext.Provider
      value={{
        state,
        dispatch,
        fetchInquiry,
        submitPayment,
        submitPaymentAuthenticate,
        fetchPromo,
        fetchAfterDiscount,
        fetchInstallments,
        cancelTransaction,
        submitEnrollment
      }}
    >
      {children}
    </AppContext.Provider>
  )
}

export { AppProvider, AppContext }
