import Inquiry from 'models/Inquiry'
import { Promo } from 'models/Promo'
import { AfterDiscount } from 'models/AfterDiscount'
import { Installment } from 'models/Installment'

type ActionMap<M extends { [index: string]: any }> = {
  [Key in keyof M]: M[Key] extends undefined ? { type: Key } : { type: Key; payload: M[Key] }
}

export type InquiryType = {
  inquiry: Inquiry | null
  promo: Promo | null
  afterDiscount: boolean
  installments: Installment[] | null
}

export type SessionActionPayload = {
  SET_INQUIRY: Inquiry
  SET_PROMO: Promo | null
  SET_AFTER_DISCOUNT: boolean
  SET_INSTALLMENT: Installment[] | null
}

export type InquiryActionType = ActionMap<SessionActionPayload>[keyof ActionMap<SessionActionPayload>]
export const inquiryReducer = (state: InquiryType, action: InquiryActionType) => {
  switch (action.type) {
    case 'SET_INQUIRY':
      state.inquiry = action.payload
      return state
    case 'SET_PROMO':
      state.promo = action.payload
      return state
    case 'SET_AFTER_DISCOUNT':
      state.afterDiscount = action.payload
      return state
    case 'SET_INSTALLMENT':
      state.installments = action.payload
      return state
    default:
      // throw new Error('Unknown action type')
      return state
  }
}
